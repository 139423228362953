import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Col, Form, Row } from 'antd';
import classNames from 'classnames';
import {
  canBeEditable,
  momentizeObjectDates,
  readOnlyPercentRender,
  readOnlyPriceRender,
} from '@core/utils/methods';
import { ACTUAL_DATE, DECIMAL_VALIDATOR } from '@core/utils/validators';
import { DOLLAR_PREFIX, PERCENT_PREFIX, YES_NO_GROUP } from '@models/constants';
import {
  FormProps,
  LeasingPermission,
  LeasingWorkflowUpdateData,
} from '@models/interfaces';
import { Panel } from '@shared/components';
import {
  Autocomplete,
  Checkbox,
  DatePicker,
  Input,
  InputNumber,
  RadioGroup,
  ShowMoreInput,
} from '@shared/modules';
import { DDVSelectors } from '@store/selectors';
import { STRUCTURAL_INFO_DATES } from '../../models/constants';

interface Props extends FormProps {
  data: LeasingWorkflowUpdateData['structural'];
  permissions: LeasingPermission.StructuralFields;
}

export const StructuralPanel: FC<Props> = ({
  form,
  isEditing,
  data,
  onValuesChange,
  permissions: {
    isStructuralRequired,
    structuralOrdered,
    structuralServiceId,
    structuralResultId,
    passingStructuralReceived,
    structuralComment: structuralCommentPermissions,
    structuralTowerPercent,
    isStructuralAdequate,
    structuralFoundationPercent,
    structuralTIARevCodeId,
    structuralVendorId,
    failingStructuralReceived,
    modCost,
    orderModDesignDate,
    modDesignComplete,
    PMSAReceivedDate,
    PMSATowerPercent,
    PMSAFoundationPercent,
    ATTModApprovedDate,
    IBMEligibleId,
    IBMApproved,
    modConstructionStart,
    modConstructionComplete,
    PMIModTurnoverDate,
  },
}: PropsWithChildren<Props>) => {
  const {
    StructuralVendorDDV,
    StructuralServiceDDV,
    StructuralResultDDV,
    StructuralTIARevCodeDDV,
    IBMEligibleDDV,
  } = useSelector(
    DDVSelectors.getDDVValuesAsOptionsByTypes([
      'StructuralVendor',
      'StructuralService',
      'StructuralResult',
      'StructuralTIARevCode',
      'IBMEligible',
    ]),
  );

  useEffect(() => {
    if (data) {
      const { structuralComment, ...restData } = data;

      form?.setFieldsValue(
        momentizeObjectDates<LeasingWorkflowUpdateData['structural']>(
          restData,
          STRUCTURAL_INFO_DATES,
        ),
      );
    }
  }, [isEditing]);

  return (
    <Panel header="Structural" className="structural-panel" collapsible>
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 14 }}
        onValuesChange={onValuesChange}
        form={form}
      >
        <Row>
          <Col span="12">
            <RadioGroup
              id="isStructuralRequired"
              label="Structural Required"
              isEditing={canBeEditable(isStructuralRequired, isEditing)}
              buttons={YES_NO_GROUP}
            />
            <DatePicker
              id="structuralOrdered"
              label="Structural Ordered"
              isEditing={canBeEditable(structuralOrdered, isEditing)}
              formItemProps={{
                rules: ACTUAL_DATE(data?.structuralOrdered),
              }}
            />
            <Autocomplete
              id="structuralVendorId"
              label="Structural Vendor"
              options={StructuralVendorDDV}
              isEditing={canBeEditable(structuralVendorId, isEditing)}
            />
            <Input
              id="structuralPOIssued"
              label="Structural PO Issued"
              isEditing={false}
            />
            <Autocomplete
              id="structuralServiceId"
              label="Structural Service"
              options={StructuralServiceDDV}
              isEditing={canBeEditable(structuralServiceId, isEditing)}
            />
            <Autocomplete
              id="structuralResultId"
              label="Structural Result"
              options={StructuralResultDDV}
              isEditing={canBeEditable(structuralResultId, isEditing)}
            />
            <DatePicker
              id="passingStructuralReceived"
              label="Passing Structural Received"
              isEditing={canBeEditable(passingStructuralReceived, isEditing)}
              formItemProps={{
                rules: ACTUAL_DATE(data?.passingStructuralReceived),
              }}
            />
            <ShowMoreInput
              id="structuralComment"
              label="Structural Comments"
              data={data.structuralComment}
              form={form}
              isEditing={canBeEditable(structuralCommentPermissions, isEditing)}
            />
            <div className="structural-panel__row-wrapper">
              <InputNumber
                id="structuralTowerPercent"
                label="Structural Tower Percent"
                elementProps={{ formatter: PERCENT_PREFIX }}
                formItemProps={{ rules: DECIMAL_VALIDATOR }}
                className={classNames('structural-tower-percent', 'ant-col-17')}
                readOnlyElementRender={readOnlyPercentRender}
                isEditing={canBeEditable(structuralTowerPercent, isEditing)}
              />
              <Checkbox
                id="isStructuralAdequate"
                className={classNames('adequate', 'ant-col-5')}
                label="Adequate"
                formItemProps={{
                  wrapperCol: { span: 5 },
                  labelCol: { span: 16 },
                }}
                isEditing={canBeEditable(isStructuralAdequate, isEditing)}
              />
            </div>
            <InputNumber
              id="structuralFoundationPercent"
              label="Structural Foundation Percent"
              elementProps={{ formatter: PERCENT_PREFIX }}
              readOnlyElementRender={readOnlyPercentRender}
              isEditing={canBeEditable(structuralFoundationPercent, isEditing)}
            />
            <Autocomplete
              id="structuralTIARevCodeId"
              label="Structural TIA Rev Code"
              options={StructuralTIARevCodeDDV}
              isEditing={canBeEditable(structuralTIARevCodeId, isEditing)}
            />
            <DatePicker
              id="failingStructuralReceived"
              label="Failing Structural Received"
              isEditing={canBeEditable(failingStructuralReceived, isEditing)}
              formItemProps={{
                rules: ACTUAL_DATE(data?.failingStructuralReceived),
              }}
            />
          </Col>
          <Col span="12">
            <InputNumber
              id="modCost"
              label="Mod Cost"
              elementProps={{ formatter: DOLLAR_PREFIX }}
              readOnlyElementRender={readOnlyPriceRender}
              isEditing={canBeEditable(modCost, isEditing)}
            />
            <DatePicker
              id="orderModDesignDate"
              label="Order Mod Design"
              isEditing={canBeEditable(orderModDesignDate, isEditing)}
            />
            <DatePicker
              id="modDesignComplete"
              label="Mod Design Complete"
              isEditing={canBeEditable(modDesignComplete, isEditing)}
              formItemProps={{
                rules: ACTUAL_DATE(data?.modDesignComplete),
              }}
            />
            <DatePicker
              id="PMSAReceivedDate"
              label="PMSA Received"
              isEditing={canBeEditable(PMSAReceivedDate, isEditing)}
            />
            <InputNumber
              id="PMSATowerPercent"
              label="PMSA Tower Percent"
              elementProps={{ formatter: PERCENT_PREFIX }}
              readOnlyElementRender={readOnlyPercentRender}
              isEditing={canBeEditable(PMSATowerPercent, isEditing)}
            />
            <InputNumber
              id="PMSAFoundationPercent"
              label="PMSA Foundation Percent"
              elementProps={{ formatter: PERCENT_PREFIX }}
              readOnlyElementRender={readOnlyPercentRender}
              isEditing={canBeEditable(PMSAFoundationPercent, isEditing)}
            />
            <DatePicker
              id="ATTModApprovedDate"
              label="ATT Mod Approved"
              isEditing={canBeEditable(ATTModApprovedDate, isEditing)}
            />
            <Autocomplete
              id="IBMEligibleId"
              label="IBM Eligible"
              options={IBMEligibleDDV}
              isEditing={canBeEditable(IBMEligibleId, isEditing)}
            />
            <DatePicker
              id="IBMApproved"
              label="IBM Approved"
              isEditing={canBeEditable(IBMApproved, isEditing)}
              formItemProps={{
                rules: ACTUAL_DATE(data?.IBMApproved),
              }}
            />
            <DatePicker
              id="modConstructionStart"
              label="Mod Construction Start"
              isEditing={canBeEditable(modConstructionStart, isEditing)}
              formItemProps={{
                rules: ACTUAL_DATE(data?.modConstructionStart),
              }}
            />
            <DatePicker
              id="modConstructionComplete"
              label="Mod Construction Complete"
              isEditing={canBeEditable(modConstructionComplete, isEditing)}
              formItemProps={{
                rules: ACTUAL_DATE(data?.modConstructionComplete),
              }}
            />
            <DatePicker
              id="PMIModTurnoverDate"
              label="PMI Mod Turnover"
              isEditing={canBeEditable(PMIModTurnoverDate, isEditing)}
            />
          </Col>
        </Row>
      </Form>
    </Panel>
  );
};
