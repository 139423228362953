export const replaceUndefinedWithNull = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map(replaceUndefinedWithNull);
  }

  if (obj && typeof obj === 'object') {
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [
        key,
        value === undefined ? null : replaceUndefinedWithNull(value),
      ]),
    );
  }

  return obj;
};
