import React, { FC, PropsWithChildren } from 'react';
import { Form, Input } from 'antd';
import { Modal } from '@shared/modules';
import { EmailEditorModalProps } from '../models';

const { useForm } = Form;

export const EmailEditor: FC<EmailEditorModalProps> = ({
  visible,
  onClose,
  onOk,
  emailText,
}: PropsWithChildren<EmailEditorModalProps>) => {
  const [form] = useForm();

  const onModalClose = () => {
    form.resetFields();
    onClose();
  };

  const onModalOk = () => {
    const emailBody: string = form.getFieldValue('emailBody');

    if (!emailBody.trim()) {
      form.setFields([
        {
          name: 'emailBody',
          errors: ['Please write the main content of the email.'],
        },
      ]);

      return;
    }

    onOk(emailBody);
  };

  return (
    <Modal
      className="prov-sof-email-editor"
      visible={visible}
      onCancel={onModalClose}
      onOk={onModalOk}
      okText="Save"
      title="Email message"
      width="70%"
      closable
    >
      <Form
        className="prov-sof-email-editor__form"
        form={form}
        layout="vertical"
      >
        <Form.Item
          label="Email Body"
          name="emailBody"
          initialValue={emailText}
          rules={[
            {
              required: true,
              message: 'Please write the main content of the email. ',
            },
          ]}
        >
          <Input.TextArea
            rows={10}
            placeholder="Write the main content of your email here"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
