import React, { FC, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { HttpService } from '@core/services';
import { SOFToolHttpService } from '@core/services/http';
import { dateWorker } from '@core/utils/date-worker';
import { removeEmptyFields } from '@core/utils/methods';
import { SOFToolOrderInfo, SOFToolOrderInfoData } from '@models/interfaces';
import { SOFTooDetailsDriversType } from '@models/types';
import { HeaderBar } from '@shared/components';
import {
  ProjectCategoriesSelectors,
  SOFToolSelectors,
  UserSelectors,
} from '@store/selectors';
import { SOFActionButtons, SOFServicesTable } from './components';
import { EmailEditor, PreviewModal } from './modals';
import { INITIAL_EMAIL_BODY, SOF_TOOL_HEADER_SITE_OPTIONS } from './models';
import { hasData } from './validators';

import './styles.scss';

export const SOFToolDetails: FC = () => {
  const SOFToolService = useMemo(
    () => HttpService.getHttpRequests(SOFToolHttpService),
    [],
  );

  const details = useSelector(SOFToolSelectors.detailsData);
  const detailsHeaderData = useSelector(SOFToolSelectors.detailsHeaderData);
  const previewSrc = useSelector(SOFToolSelectors.previewSrc);
  const isFetching = useSelector(SOFToolSelectors.isFetching);
  const projectCategoriesTableVisible = useSelector(
    ProjectCategoriesSelectors.getProjectCategoriesTableVisible,
  );
  const projectId = useSelector(SOFToolSelectors.getProjectId);
  const projectType = useSelector(SOFToolSelectors.getProjectType);
  const projectTypePermission = useSelector(
    UserSelectors.canEditProjectByProjectType(projectType),
  );

  const [values, setValues] = useState<SOFToolOrderInfo>({
    orderDate: dateWorker(),
    orderedBy: '',
    data: [],
    attachments: null,
  });
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);
  const [emailEditorVisible, setEmailEditorVisible] = useState(false);
  const [SOFTableData, setSOFTableData] = useState<SOFTooDetailsDriversType[]>(
    details!.drivers,
  );
  const [emailText, setEmailText] = useState<string>(INITIAL_EMAIL_BODY);

  const configurationPanelChanges = (
    newValues: Partial<Omit<SOFToolOrderInfo, 'data'>>,
  ): void => {
    setValues((prevState: SOFToolOrderInfo) => ({
      ...prevState,
      ...newValues,
    }));
  };

  const chooseData = (
    checked: boolean,
    { servicesRequired, ...selectedRow }: SOFToolOrderInfoData,
  ): void => {
    setValues((prevState: SOFToolOrderInfo) => ({
      ...prevState,
      data: checked
        ? [...prevState.data, selectedRow]
        : [...prevState.data].filter(
            (SOFToolOrderInfoDataItem: SOFToolOrderInfoData) =>
              SOFToolOrderInfoDataItem.id !== selectedRow.id,
          ),
    }));
  };

  const openEmailEditorModal = () => {
    setEmailEditorVisible(true);
  };

  const makePreview = async (): Promise<void> => {
    const { data, orderDate, orderedBy } = values;

    await SOFToolService.previewPDF(projectId, {
      data,
      orderDate,
      orderedBy,
    });

    setPreviewVisible(!previewVisible);
  };

  const send = async (): Promise<void> => {
    const { data, orderDate, orderedBy, attachments } = values;

    await SOFToolService.sendPDF(
      projectId,
      removeEmptyFields({
        attachments,
        orderData: JSON.stringify({
          orderDate,
          orderedBy,
          data,
        }),
        emailText: JSON.stringify(emailText),
      }),
    );
  };

  const cancel = () => {
    setValues({
      orderDate: dateWorker(),
      orderedBy: '',
      data: [],
      attachments: null,
    });
    setSOFTableData(details.drivers);
  };

  return (
    <div className="prov-sof-tool-details">
      <HeaderBar
        site={detailsHeaderData.site}
        siteOptions={SOF_TOOL_HEADER_SITE_OPTIONS}
        showTowerOwner={false}
        withoutTabPanel
      />
      <div
        className={classNames('scrolling-wrap', {
          'categories-table-open': projectCategoriesTableVisible,
        })}
      >
        <SOFServicesTable
          detailsData={details}
          values={values}
          configurationPanelChanges={configurationPanelChanges}
          chooseData={chooseData}
          isFetching={isFetching}
          projectTypePermission={projectTypePermission}
          data={SOFTableData}
          setData={setSOFTableData}
        />
      </div>
      <SOFActionButtons
        send={send}
        cancel={cancel}
        preview={makePreview}
        disabled={!isFetching ? hasData(values) : isFetching}
        cancelDisabled={
          !values.orderedBy && !values.data?.length && !values.attachments
        }
        openEmailEditorModal={openEmailEditorModal}
      />
      <PreviewModal
        visible={previewVisible}
        src={previewSrc}
        onClose={(): void => setPreviewVisible(!previewVisible)}
      />
      <EmailEditor
        visible={emailEditorVisible}
        onClose={() => {
          setEmailEditorVisible(!emailEditorVisible);
        }}
        onOk={(emailData: string) => {
          setEmailText(emailData);
          setEmailEditorVisible(!emailEditorVisible);
        }}
        emailText={emailText}
      />
    </div>
  );
};
