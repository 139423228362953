import React, { ReactNode } from 'react';
import {
  DateString,
  DropDownSelectorValues,
} from '@symfa-inc/providence-types';
import { ColumnsType } from 'antd/lib/table';
import { getDDVById, mainDateFormat } from '@core/utils/methods';
import { actionButtons } from '@models/constants';
import { JurisdictionDetailsPermittingType } from '@models/interfaces';
import { ColumnsFunc } from '@models/types';
import { ViewElement } from '@shared/components';

export const getPermitTypeTableColumns = (
  editFunc: ColumnsFunc<JurisdictionDetailsPermittingType>,
  deleteFunc: ColumnsFunc<JurisdictionDetailsPermittingType>,
  showPermitExpireDate: boolean,
  isEditing: boolean = false,
): ColumnsType => [
  {
    title: 'Permit Type',
    dataIndex: 'permitType',
    key: 'permitType',
    render: (value: string): string =>
      getDDVById(DropDownSelectorValues.PermitType, value).value,
  },
  {
    title: 'Expected Permit Lead Time',
    dataIndex: 'expectedPermitLeadTime',
    key: 'expectedPermitLeadTime',
  },
  {
    title: 'Permit and Application Fees',
    dataIndex: 'permitAndApplicationFee',
    key: 'permitAndApplicationFee',
  },
  {
    title: 'Required Info for Obtaining Permit',
    dataIndex: 'requiredInfoForObtainingPermit',
    key: 'requiredInfoForObtainingPermit',
    render: (value): ReactNode => <ViewElement value={value} />,
  },
  ...(showPermitExpireDate
    ? [
        {
          title: 'Permit Expire Date',
          dataIndex: 'expireDate',
          key: 'expireDate',
          render: (expireDate: DateString) => mainDateFormat(expireDate),
        },
      ]
    : []),
  actionButtons({ editFunc, deleteFunc, width: 130, isEditing }),
];
