import { MAIN_PACE_TASK_IDs, PaceTaskType } from '@symfa-inc/providence-types';
import {
  ConfigProjectPaceTaskData,
  LeasingWorkflowUpdateData,
} from '@models/interfaces';
import { ProjectsEffects } from '@store/effects';

export const updateLeasingPaceTaskFields = ({
  engineeringSection,
  leasingSection,
  structuralSection,
  additionalLeasingPaceTasks,
}: {
  engineeringSection: LeasingWorkflowUpdateData['engineering'];
  leasingSection: LeasingWorkflowUpdateData['leasing'];
  structuralSection: LeasingWorkflowUpdateData['structural'];
  additionalLeasingPaceTasks?: ConfigProjectPaceTaskData<
    LeasingWorkflowUpdateData['leasing']
  >[];
}): void => {
  ProjectsEffects.updatePaceTaskFieldsByConfig(engineeringSection, [
    {
      id: MAIN_PACE_TASK_IDs.finalCDsReviewedLeasing,
      dateType: PaceTaskType.ActualDate,
      field: 'finalCDsReviewedLeasing',
    },
    {
      id: MAIN_PACE_TASK_IDs.passingMountAnalysisReviewedLeasing,
      dateType: PaceTaskType.ActualDate,
      field: 'passingMountAnalysisReviewedLeasing',
    },
    {
      id: MAIN_PACE_TASK_IDs.prelimCDsApprovedLeasing,
      dateType: PaceTaskType.ActualDate,
      field: 'prelimCDsApprovedLeasing',
    },
    {
      id: MAIN_PACE_TASK_IDs.prelimCDsReviewedLeasing,
      dateType: PaceTaskType.ActualDate,
      field: 'prelimCDsApprovedLeasing',
    },
  ]);
  ProjectsEffects.updatePaceTaskFieldsByConfig(leasingSection, [
    {
      id: MAIN_PACE_TASK_IDs.leaseAuditComplete,
      dateType: PaceTaskType.ActualDate,
      field: 'leaseAuditComplete',
    },
    {
      id: MAIN_PACE_TASK_IDs.SOFIssued,
      dateType: PaceTaskType.ActualDate,
      field: 'SOFIssued',
    },
    {
      id: MAIN_PACE_TASK_IDs.coloAppDrafted,
      dateType: PaceTaskType.ActualDate,
      field: 'coloAppDrafted',
    },
    {
      id: MAIN_PACE_TASK_IDs.coloAppApproved,
      dateType: PaceTaskType.ActualDate,
      field: 'coloAppApproved',
    },
    {
      id: MAIN_PACE_TASK_IDs.coloAppSubmitted,
      dateType: PaceTaskType.ActualDate,
      field: 'coloAppSubmitted',
    },
    {
      id: MAIN_PACE_TASK_IDs.PALOrNPAReceived,
      dateType: PaceTaskType.ActualDate,
      field: 'PALOrNPAReceived',
    },
    {
      id: MAIN_PACE_TASK_IDs.leaseDraftReceived,
      dateType: PaceTaskType.ActualDate,
      field: 'leaseDraftReceived',
    },
    {
      id: MAIN_PACE_TASK_IDs.LMPSSubmitted,
      dateType: PaceTaskType.ActualDate,
      field: 'LMPSSubmitted',
    },
    {
      id: MAIN_PACE_TASK_IDs.LMPSApproved,
      dateType: PaceTaskType.ActualDate,
      field: 'LMPSApproved',
    },
    {
      id: MAIN_PACE_TASK_IDs.leaseOrAmendmentFullyExecuted,
      dateType: PaceTaskType.ActualDate,
      field: 'leaseOrAmendmentFullyExecuted',
    },
    {
      id: MAIN_PACE_TASK_IDs.NTPApproved,
      dateType: PaceTaskType.ActualDate,
      field: 'NTPApproved',
    },
    {
      id: MAIN_PACE_TASK_IDs.NTPRequested,
      dateType: PaceTaskType.ActualDate,
      field: 'NTPRequested',
    },
    ...(additionalLeasingPaceTasks || []),
  ]);
  ProjectsEffects.updatePaceTaskFieldsByConfig(structuralSection, [
    {
      id: MAIN_PACE_TASK_IDs.structuralOrdered,
      dateType: PaceTaskType.ActualDate,
      field: 'structuralOrdered',
    },
    {
      id: MAIN_PACE_TASK_IDs.passingStructuralReceived,
      dateType: PaceTaskType.ActualDate,
      field: 'passingStructuralReceived',
    },
    {
      id: MAIN_PACE_TASK_IDs.failingStructuralReceived,
      dateType: PaceTaskType.ActualDate,
      field: 'failingStructuralReceived',
    },
    {
      id: MAIN_PACE_TASK_IDs.modDesignComplete,
      dateType: PaceTaskType.ActualDate,
      field: 'modDesignComplete',
    },
    {
      id: MAIN_PACE_TASK_IDs.IBMApproved,
      dateType: PaceTaskType.ActualDate,
      field: 'IBMApproved',
    },
    {
      id: MAIN_PACE_TASK_IDs.modConstructionStart,
      dateType: PaceTaskType.ActualDate,
      field: 'modConstructionStart',
    },
    {
      id: MAIN_PACE_TASK_IDs.modConstructionComplete,
      dateType: PaceTaskType.ActualDate,
      field: 'modConstructionComplete',
    },
  ]);
};
