import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'antd';
import { HttpService } from '@core/services';
import { EquipmentHttpService } from '@core/services/http';
import { createOnChangeModalVisibility } from '@core/utils/methods';
import {
  BROWSE_DEFAULT_FILTER_VALUE,
  browseFiltersChange,
  tableCount,
} from '@models/constants';
import {
  EquipmentBrowseConfigureFilter,
  MainBrowseFilter,
} from '@models/interfaces';
import { MainEquipmentBrowseConfigureFilter } from '@models/types';
import { BrowsePage } from '@shared/modules';
import { EquipmentSelectors } from '@store/selectors';
import { convertToTableData } from './helpers';
import { Filters } from './modals';
import {
  EQUIPMENT_BROWSE_COLUMNS,
  equipmentBrowseDefaultConfigureFilter,
} from './models';

import './styles.scss';

const { useForm } = Form;

export const EquipmentBrowse: FC = () => {
  const [form] = useForm();

  const equipmentsBrowse = useSelector(
    EquipmentSelectors.getEquipmentBrowseData,
  );

  const { items, total } = convertToTableData(equipmentsBrowse);

  const [filterValue, setFilterValue] = useState<MainBrowseFilter>(
    BROWSE_DEFAULT_FILTER_VALUE,
  );
  const [configureFilter, setConfigureFilter] = useState(
    equipmentBrowseDefaultConfigureFilter,
  );

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      await HttpService.getHttpRequests(
        EquipmentHttpService,
      ).getEquipmentsByFilter(filterValue);
    };

    fetchData();
  }, [filterValue]);

  const onSearchChanges = (search: string): void => {
    setFilterValue((prevState: MainEquipmentBrowseConfigureFilter) => {
      if (prevState?.search !== search) {
        return {
          ...prevState,
          page: 1,
          search,
        };
      }

      return {
        ...prevState,
        search,
      };
    });
  };

  const onConfigureFilterChanges = (
    value: EquipmentBrowseConfigureFilter,
  ): void => {
    setConfigureFilter((prevState: EquipmentBrowseConfigureFilter) => ({
      ...prevState,
      ...value,
    }));
  };

  const onClear = (): void => {
    form?.resetFields();
    setConfigureFilter(equipmentBrowseDefaultConfigureFilter);
  };

  const onApplyConfigureFilter = (): void => {
    setFilterValue({
      ...filterValue,
      ...configureFilter,
    });
  };

  return (
    <BrowsePage
      className="prov-equipment-browse"
      columns={EQUIPMENT_BROWSE_COLUMNS}
      data={items}
      onSearchChanges={onSearchChanges}
      place={tableCount(true)}
      onApplyFilter={onApplyConfigureFilter}
      cancelButtonProps={{ onClick: onClear }}
      tableProps={{
        pagination: {
          total,
          current: filterValue.page,
        },
        module: 'equipment',
      }}
      onChange={browseFiltersChange(setFilterValue)}
      onChangeFilterModalVisibility={createOnChangeModalVisibility(
        form,
        configureFilter,
        filterValue,
        setConfigureFilter,
      )}
      filters={
        <Filters
          value={configureFilter}
          onChanges={onConfigureFilterChanges}
          formProps={{ form }}
        />
      }
      filterFormProps={{ labelCol: { span: 8 }, form }}
      redirectToDetailsRowKey={false}
    />
  );
};
