import React, {
  FC,
  PropsWithChildren,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import {
  ProjectStatus,
  Undefinable,
  UserRole,
} from '@symfa-inc/providence-types';
import { Col, Form, Row } from 'antd';
import { SelectValue } from 'antd/es/select';
import { HttpService } from '@core/services';
import { ProjectHttpService } from '@core/services/http';
import { useDidUpdateEffect } from '@core/utils/hooks';
import { normalize } from '@core/utils/methods';
import { serverValidator } from '@core/utils/validators';
import { INPUT_POSITIVE_NUMBER_VALIDATOR } from '@models/constants';
import { OptionProps } from '@models/interfaces';
import { Panel } from '@shared/components';
import {
  Autocomplete,
  InputNumber,
  ProjectStatus as ProjectStatusComponent,
  ShowMoreInput,
} from '@shared/modules';
import {
  DDVSelectors,
  ProjectSelectors,
  SiteSelectors,
  UserSelectors,
} from '@store/selectors';
import { ProjectInformationProps } from './models';

export const ProjectInformation: FC<ProjectInformationProps> = ({
  form,
  isEditing,
  isNew = false,
  colSpan = 12,
  className = '',
  collapsible = false,
  permissions: {
    FALocationNumber,
    projectBundleID,
    projectType,
    projectWorkType,
    projectStatus,
    progressStatus,
    ATnTPM,
    siteAcqVendor,
    civilVendor,
    projectSubtype,
    projectNote: projectNotePermission,
    providenceAgent,
    holdReason: holdReasonPermission,
  },
  data: { projectNote, ...data },
  onChange,
}: PropsWithChildren<ProjectInformationProps>) => {
  const dropdownsOptions = useSelector(DDVSelectors.getDDVOptions);
  const FALocationNumberOptions = useSelector(
    SiteSelectors.getFALocationNumberOptions,
  );
  const providenceAgents = useSelector(ProjectSelectors.getProvidenceAgents);
  const currentProjectType = useSelector(ProjectSelectors.getProjectType);
  const projectTypePermission = useSelector(
    UserSelectors.canEditProjectByProjectType(currentProjectType),
  );
  const isAdmin = useSelector(UserSelectors.getUserRoles)?.includes(
    UserRole.Admin,
  );

  const [currentSite, setCurrentSite] = useState<Undefinable<string>>(
    data.siteId,
  );

  useEffect(() => {
    form?.setFieldsValue({
      ...data,
      FALocationNumber: currentSite,
    });
  }, [isEditing]);

  useDidUpdateEffect(() => {
    if (currentSite && isNew) {
      HttpService.getHttpRequests(ProjectHttpService).getProvidenceAgent(
        currentSite,
      );
    }
  }, [currentSite]);

  return (
    <Form
      labelCol={{ span: colSpan === 12 ? 7 : 10 }}
      wrapperCol={{ span: 14 }}
      className={className}
      form={form}
      onValuesChange={onChange}
    >
      <Panel header="Project Information" collapsible={collapsible}>
        <Row>
          <Col span={colSpan}>
            <Autocomplete
              id="FALocationNumber"
              label="FA Location Number"
              isEditing={
                isEditing && projectTypePermission && !!FALocationNumber
              }
              options={FALocationNumberOptions}
              formItemProps={{
                rules: [
                  serverValidator(
                    {
                      method: 'siteOnValidation',
                      errorMessage:
                        'Project cannot be created for Inactive Site',
                    },
                    ProjectHttpService,
                  ),
                  {
                    required: true,
                    message: 'FA Location Number is required!',
                  },
                ],
              }}
              elementProps={{
                onChange: (value: SelectValue): void => {
                  setCurrentSite(value as string);
                },
              }}
            />
            {!isNew && (
              <InputNumber
                id="projectBundleID"
                label="Project Bundle ID"
                isEditing={
                  isEditing && projectTypePermission && !!projectBundleID
                }
                formItemProps={{
                  normalize,
                  rules: [INPUT_POSITIVE_NUMBER_VALIDATOR],
                }}
              />
            )}
            <Autocomplete
              id="projectType"
              label="Project Type"
              isEditing={isEditing && projectTypePermission && !!projectType}
              options={dropdownsOptions?.ProjectType}
              formItemProps={{
                rules: [
                  {
                    required: true,
                    message: 'Project Type is required!',
                  },
                ],
              }}
            />
            <Autocomplete
              id="projectWorkType"
              label="Project Work Type"
              isEditing={
                isEditing && projectTypePermission && !!projectWorkType
              }
              options={dropdownsOptions?.ProjectWorkType}
            />
            <Autocomplete
              id="projectSubtype"
              label="Project Subtype"
              isEditing={isEditing && projectTypePermission && !!projectSubtype}
              options={dropdownsOptions?.ProjectSubtype}
              formItemProps={{
                rules: [
                  {
                    required: true,
                    message: 'Project Subtype is required!',
                  },
                ],
              }}
            />
            <Autocomplete
              id="projectStatus"
              label="Project Status"
              isEditing={isEditing && projectTypePermission && !!projectStatus}
              options={dropdownsOptions?.ProjectStatus?.map(
                (option: OptionProps) =>
                  option.value === ProjectStatus.Hold
                    ? {
                        ...option,
                        disabled: !holdReasonPermission,
                        ...(!isAdmin
                          ? { title: 'Only Admin could set Hold status.' }
                          : {}),
                      }
                    : option,
              )}
              readOnlyElementRender={(value: string): ReactElement => (
                <ProjectStatusComponent status={value} />
              )}
            />
          </Col>
          <Col span={colSpan}>
            <Autocomplete
              id="progressStatus"
              label="Progress Status"
              isEditing={isNew && projectTypePermission && !!progressStatus}
              options={dropdownsOptions?.ProgressStatus}
            />
            <Autocomplete
              id="ATnTPM"
              label="AT&T PM"
              isEditing={isEditing && projectTypePermission && !!ATnTPM}
              options={dropdownsOptions?.ATnTPM}
            />
            <Autocomplete
              id="siteAcqVendor"
              label="Site Acq Vendor"
              isEditing={isEditing && projectTypePermission && !!siteAcqVendor}
              options={dropdownsOptions?.SiteACQVendor}
              formItemProps={{
                rules: [
                  {
                    required: true,
                    message: 'Site Acq Vendor is required!',
                  },
                ],
              }}
            />
            <Autocomplete
              id="providenceAgent"
              label="Providence Agent"
              isEditing={
                isEditing && projectTypePermission && !!providenceAgent
              }
              options={providenceAgents}
              elementProps={{ allowClear: true }}
            />
            <Autocomplete
              id="civilVendor"
              label="Civil Vendor"
              isEditing={isEditing && projectTypePermission && !!civilVendor}
              options={dropdownsOptions?.CivilVendor}
            />
            <ShowMoreInput
              id="projectNote"
              label="Project Notes"
              isEditing={
                isEditing && projectTypePermission && !!projectNotePermission
              }
              data={projectNote}
              form={form}
            />
          </Col>
        </Row>
      </Panel>
    </Form>
  );
};
